import React from "react";
import "./styles/loading.css";

const Loading = ({ sm, md, lg, height }) => {
  let size = "80px";
  let borderSize = "6px";
  let defaultHeight = "100vh";

  if (sm) {
    size = "30px";
    borderSize = "2px";
  }
  if (md) {
    size = "60px";
    borderSize = "4px";
  }
  if (lg) {
    size = "80px";
    borderSize = "6px";
  }

  if (height) {
    defaultHeight = height;
  }

  return (
    <div className="loaderBody" style={{ height: defaultHeight }}>
      <div
        className="loader"
        style={{ width: size, height: size, borderWidth: borderSize }}
      ></div>
    </div>
  );
};

export default Loading;
