import { makeStyles } from "@material-ui/core";

const menubarStyles = makeStyles(() => ({
  appBar: {
    top: "auto",
    bottom: 0,
    backgroundColor: "#eceff1",
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
    backgroundColor: "#80d8ff",
    border: " 1px solid #99dfff",
    color: "white",
  },
  menuLink :  {
    marginBottom: "-8px",
    color: "#444",
    borderBottom: "3px solid transparent"
  },
  menuLinkActive :  {
    marginBottom: "-8px",
    color: "black",
    borderBottom: "3px solid #000"
  }
}));

export default menubarStyles;
