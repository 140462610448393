import React from "react";
import { useLocation } from "react-router-dom";

const NotFound = ({ theme }) => {
  let location = useLocation();

  let color = "black";
  if (theme && theme === "dark") {
    color = "white";
  }
  return (
    <div style={{ textAlign: "center", color: color }}>
      <h1>Opps</h1>
      <h2>Looks like the page does not exist.</h2>
      <h3>
        <code>{location.pathname}</code>
      </h3>
    </div>
  );
};

export default NotFound;
