import { makeStyles } from "@material-ui/core";

const styles = makeStyles((theme) => ({
  avatar: {
    width: "50px",
    height: "50px",
    background: "grey",
    color: "white",
  },
  moreButtonBox: {
    textAlign: "center",
  },
  menugrid: {
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px"
  },
  userMenu: {
    width: "",
  }
}));

export default styles;
