import React from "react";

import AppBar from "@material-ui/core/AppBar";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";

import HomeIcon from "@material-ui/icons/Home";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import AddIcon from "@material-ui/icons/Add";

import menubarStyles from "./styles/menubar";
import { NavLink, useRouteMatch } from "react-router-dom";

const MenuBar = () => {
  const classes = menubarStyles();

  const { path } = useRouteMatch();

  return (
    <AppBar position="fixed" color="default" className={classes.appBar}>
      <Toolbar variant="regular">
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ padding: "5px" }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            fontSize="large"
          >
            <NavLink
              to={`${path}/home`}
              activeClassName={classes.menuLinkActive}
              className={classes.menuLink}
            >
              <HomeIcon fontSize="large" />
            </NavLink>
          </IconButton>

          <IconButton color="inherit">
            <NavLink
              to={`${path}/cashin`}
              activeClassName={classes.menuLinkActive}
              className={classes.menuLink}
            >
              <TrendingUpIcon fontSize="large" />
            </NavLink>
          </IconButton>
          <Fab color="primary" aria-label="add" className={classes.fabButton}>
            <AddIcon fontSize="large" />
          </Fab>
          <IconButton color="inherit" className="placeholder" />
          <IconButton color="inherit">
            <NavLink
              to={`${path}/cashout`}
              activeClassName={classes.menuLinkActive}
              className={classes.menuLink}
            >
              <TrendingDownIcon fontSize="large" />
            </NavLink>
          </IconButton>
          <IconButton edge="end" color="inherit">
            <NavLink
              to={`${path}/wallet`}
              activeClassName={classes.menuLinkActive}
              className={classes.menuLink}
            >
              <AccountBalanceWalletIcon fontSize="large" />
            </NavLink>
          </IconButton>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default MenuBar;
