import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Switch,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import styles_ligth from "./styles/usermenu_ligth";
import styles_dark from "./styles/usermenu_ligth";
import { setTheme } from "../../helpers/helpers";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import "./test.css";

import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

const UserMenu = ({ theme, ...restProps }) => {
  let classes = setTheme(theme, styles_ligth, styles_dark);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [darkMode, setdarkMode] = useState(false);
  const handleChange = (event) => {
    setdarkMode(!darkMode);
  };

  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  // list of items
  const monthsList = [
    { name: "Janeiro" },
    { name: "Fevereiro" },
    { name: "Março" },
    { name: "Abril" },
    { name: "Maio" },
    { name: "Junho" },
    { name: "Julho" },
    { name: "Agosto" },
    { name: "Setembro" },
  ];

  return (
    <Box>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={classes.menugrid}
      >
        <Box>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            disableRipple={true}
          >
            <Avatar alt="Daniel Barroso" className={classes.avatar}>
              DB
            </Avatar>
          </Button>

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <MenuItem onClick={handleChange}>
              <Typography style={{ paddingRight: "20px" }}>
                Modo Escuro
              </Typography>
              <Switch
                checked={darkMode}
                color="primary"
                name="darkMode"
                inputProps={{ "aria-label": "primary checkbox" }}
                className={classes.userMenu}
              />
            </MenuItem>
            <MenuItem onClick={handleClose}>Definições</MenuItem>
            <MenuItem onClick={handleClose}>Terminar</MenuItem>
          </Menu>
        </Box>

        <Box>Setembro</Box>

        <Box className={classes.moreButtonBox}>
          <IconButton aria-label="delete" className={classes.margin}>
            <MoreHorizIcon fontSize="large" />
          </IconButton>
        </Box>
      </Grid>
      <Box style={{ height: "70vh", display: "block"}}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ height: "100%" }}
        >
          <Grid item xs={12}>
            <Grid
              container
              spacing={9}
              direction="row"
              justify="center"
              alignItems="flex-start"
              style={{ textAlign: "center" }}
            >
              <Grid item xs={12}>
                <Box>
                  <Typography>Saldo Atual:</Typography>
                  <Typography>10000€</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <ArrowUpwardIcon />
                <Typography>400</Typography>
              </Grid>
              <Grid item xs={6}>
                <ArrowDownwardIcon />
                <Typography>400</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default UserMenu;
