import { Box } from "@material-ui/core";
import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import NotFound from "../components/errors/NotFound";
import Home from "./home/Home";
import globalStyles from "../global-styles";

import MenuBar from "../components/menubar";

const MainApp = () => {
  const { path } = useRouteMatch();

  const classes = globalStyles();

  const theme = "light";

  return (
    <Box className={classes.app}>
      <Box className={classes.appBox}>
        <Switch>
          <Route exact path={path}>
            <Redirect to={`${path}/home`} />
          </Route>
          <Route path={`${path}/home`}>
            <Home theme={theme} />
          </Route>
          <Route path="*">
            <NotFound theme={theme} />
          </Route>
        </Switch>
      </Box>
      <MenuBar theme={theme} />
    </Box>
  );
};

export default MainApp;
