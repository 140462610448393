import { Box } from "@material-ui/core";
import React from "react";
import UserMenu from "../../components/userMenu";

const Home = ({ theme, ...restProps }) => {
  
  return (
    <>
      <Box>
        <UserMenu theme={theme} />
      </Box>
    </>
  );
};

export default Home;
