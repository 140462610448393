const getJwtToken = () => {
  return localStorage.getItem("token");
}

const subStringTitle = (title) => {
  if (title.length > 24) {
    return title.substr(0, 24) + '..';
  } else {
    return title;
  }
};

const setTheme = (theme, styles_ligth, styles_dark) => {
  let classes;
  theme === "light" ? (classes = styles_ligth()) : (classes = styles_dark());
  return classes;
}

export { subStringTitle, getJwtToken, setTheme };
