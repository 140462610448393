import { makeStyles } from "@material-ui/core";

const globalStyles = makeStyles({
  app: {
    background: "#eceff1",
    height: "100vh",
    overflow: "auto",
  },
  appBox: {
    padding: "10px",
    paddingBottom: "50",
    backgroundColor: "transparent !important",
  },
});

export default globalStyles;
