import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import NotFound from "./components/errors/NotFound";
import MainApp from "./pages/MainApp";
import SignIn from "./pages/auth/SignIn";
import React from "react";
import Loading from "./components/loading";

const CustomRoute = ({ isPrivate, isPublic, ...rest }) => {
  if (isPrivate) {
    return <Loading />;
  }

  return <Route {...rest} />;
};

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <CustomRoute exact path="/">
          <Redirect to="/app" />
        </CustomRoute>
        <CustomRoute isPublic path="/signin" component={SignIn} />
        <CustomRoute path="/app" component={MainApp} />
        <CustomRoute path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
